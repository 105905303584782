import React from 'react';
import ParallaxedImage from '../components/ParallaxedImage';
import parse, { domToReact, attributesToProps } from 'html-react-parser';

export default function parseWpContent(content) {
  return parse(content, {
    replace: (domNode) => {
      const props = attributesToProps(domNode.attribs);

      if (domNode.attribs?.class?.includes('wp-block-image')) {
        return <ParallaxedImage {...props}>{domToReact(domNode.children)}</ParallaxedImage>;
      }
    },
  });
}
