import React, { useRef } from 'react';
import { useScroll, useTransform, motion, useSpring } from 'framer-motion';
import { parallaxedImageHolder } from './ParallaxedImage.module.scss';
import { useIsSmallScreen } from '../hooks/useWindowSizes';

export default function ParallaxedImage({ children, isFeaturedImage, ...props }) {
  const ref = useRef(null);
  const isSmallScreen = useIsSmallScreen(780);

  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'start start'] });
  const distance = 40;

  const opacityTransformed = useTransform(scrollYProgress, [0, 0.6], [0.5, 1]);
  const opacity = useSpring(opacityTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const yTransformed = useTransform(scrollYProgress, [0, 1], [distance, -50]);
  const y = useSpring(yTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const xTransformed = useTransform(scrollYProgress, [0, 0.6], [distance, -50]);
  const x = useSpring(xTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const xInvertedTransformed = useTransform(scrollYProgress, [0, 0.6], [-distance, 0]);
  const xInverted = useSpring(xInvertedTransformed, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <div className={`${parallaxedImageHolder} ${isFeaturedImage ? 'featuredImage' : ''} wp-block`}>
      <figure {...props} ref={ref}>
        {!isSmallScreen ? (
          <motion.div style={{ y, opacity: opacity }} className="imageHolder">
            {children}
          </motion.div>
        ) : (
          <div data-case="default" className="imageHolder">
            {children}
          </div>
        )}
      </figure>
    </div>
  );
}
