import React from 'react';
import useSiteMetadata from '../hooks/useSiteMetadata';

export const Seo = ({ title, description, pathname, children }) => {
  const { NODE_ENV } = process.env;

  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    //  twitterUsername
  } = useSiteMetadata();
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${NODE_ENV === 'development' ? 'http://localhost:8000' : siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    // twitterUsername,
  };
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.shareImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.shareImage} />
      <meta property="og:image" content={seo.shareImage} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:title" content={seo.title} />
      {/* <meta name="twitter:creator" content={seo.twitterUsername} /> */}
      <link rel="icon" href={seo.image} />
      {children}
    </>
  );
};

export default Seo;
