import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import { whatWeAreHolder, slideshowHolder } from './what-we-are.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import parseWpContent from '../utils/parseWpContent';

const scrollTime = 150;

const slideshowVariants = {
  initial: () => ({
    x: '-100%',
  }),
  animate: () => ({
    x: '-200%',
    transition: {
      duration: scrollTime,
      type: 'tween',
      ease: 'linear',
      repeat: Infinity,
    },
  }),
  exit: {},
};

const Slideshow = ({ images }) => {
  const justImages = images.filter((image) => image !== null);

  const Slideshow = ({ double }) => (
    <motion.ul className="slideshowInner" variants={slideshowVariants} custom={{ length: justImages.length, double: double }}>
      {justImages.map((image, i) => {
        let img;
        if (image) img = getImage(image.localFile);
        return image ? (
          <li key={image.localFile.id}>
            <GatsbyImage image={img} alt={`${img.title}`} />
            <div className="caption" dangerouslySetInnerHTML={{ __html: image.caption }} />
          </li>
        ) : null;
      })}
    </motion.ul>
  );

  return (
    <div className={slideshowHolder}>
      <Slideshow />
      <Slideshow />
      <Slideshow />
    </div>
  );
};

export default function WhatWeArePage({ data }) {
  const parsedContent = parseWpContent(data.wpPage.content);

  // console.log(title);
  return (
    <article className={whatWeAreHolder}>
      <h2>What We Are</h2>
      <section>{parsedContent}</section>
      <svg width="273" height="8" viewBox="0 0 273 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="37.4244" cy="3.91587" r="3.91616" transform="rotate(-90 37.4244 3.91587)" fill="black" />
        <circle cx="3.91616" cy="3.91587" r="3.91616" transform="rotate(-90 3.91616 3.91587)" fill="black" />
        <circle cx="169.7" cy="3.91587" r="3.91616" transform="rotate(-90 169.7 3.91587)" fill="black" />
        <circle cx="70.4938" cy="3.91587" r="3.91616" transform="rotate(-90 70.4938 3.91587)" fill="black" />
        <circle cx="202.771" cy="3.91587" r="3.91616" transform="rotate(-90 202.771 3.91587)" fill="black" />
        <circle cx="103.562" cy="3.91587" r="3.91616" transform="rotate(-90 103.562 3.91587)" fill="black" />
        <circle cx="235.839" cy="3.91587" r="3.91616" transform="rotate(-90 235.839 3.91587)" fill="black" />
        <circle cx="136.633" cy="3.91587" r="3.91616" transform="rotate(-90 136.633 3.91587)" fill="black" />
        <circle cx="268.91" cy="3.91587" r="3.91616" transform="rotate(-90 268.91 3.91587)" fill="black" />
      </svg>
    </article>
  );
}

export const Head = () => <Seo title="What we are" />;

export const data = graphql`
  query WhatWeArePageQuery {
    wpPage(slug: { eq: "what-we-are" }) {
      title
      content
      slug
    }
  }
`;
